/** @jsxImportSource @emotion/react */
import {
  Button,
  Icon,
  Input,
  Modal,
  Typography,
  useForm,
  useTheme,
} from '@quickbit/qb-design-system';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useAsyncFn,
  useLocalStorage,
  useMount,
  useSessionStorage,
} from 'react-use';
import { Layout } from 'components';
import { Order } from 'types';
import { encryptCardData } from 'utils/encryptCardData';
import { CardData, CreateOrderCardData, createOrder } from '../api';

const notEmpty = (v: string) => (v === '' ? 'Cannot be empty' : undefined);

export const TopUpDemo = () => {
  const [params] = useSearchParams();
  const userDemo = params.get('userDemo');
  const { spacing, palette, breakpoints, sizing, shape } = useTheme();
  const [merchantApiKey] = useLocalStorage<string | null>('apiKey', null);
  const [showModal, setShowModal] = useState(false);
  const [order, setOrder] = useSessionStorage<Order | undefined>(
    'top-up-demo-order',
    undefined
  );

  const [amount, setAmount] = useState(0);
  const { setters, fields, hasErrors } = useForm<CardData>(
    {
      cardHolder: '',
      creditCardNumber: '',
      cvv: '',
      expiryMonth: '',
      expiryYear: '',
    },
    {
      cardHolder: notEmpty,
      creditCardNumber: notEmpty,
      cvv: notEmpty,
      expiryMonth: notEmpty,
      expiryYear: notEmpty,
    }
  );

  const closeModal = () => {
    setShowModal(false);
    setOrder(undefined);
    setters.set({
      cardHolder: '',
      creditCardNumber: '',
      cvv: '',
      expiryMonth: '',
      expiryYear: '',
    });
  };

  const [{ loading: orderCreateLoading, error }, createNewOrder] =
    useAsyncFn(async () => {
      let creditCardData: CreateOrderCardData | undefined = undefined;
      if (!merchantApiKey) return undefined;
      if (!hasErrors) {
        const [encCard, encCvv] = await encryptCardData(merchantApiKey, fields);
        creditCardData = {
          ...fields,
          encryptedCreditCardNumber: encCard,
          encryptedCvv: encCvv,
          expiryMonth:
            fields.expiryMonth.length === 1
              ? `0${fields.expiryMonth}`
              : fields.expiryMonth,
          expiryYear:
            fields.expiryYear.length === 2
              ? `20${fields.expiryYear}`
              : fields.expiryYear,
        } as CreateOrderCardData;
      }
      const o = await createOrder({
        amount,
        currency: 'EUR',
        apiKey: merchantApiKey,
        useMigrationFlow: true,
        creditCardData,
      });
      setOrder(o);
      return o;
    }, [amount, merchantApiKey, fields, hasErrors]);

  useMount(() => {
    if (order) {
      setShowModal(true);
    }
  });

  return (
    <Layout title="Top up your account">
      <div
        css={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {merchantApiKey && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: spacing.xxxs,
            }}
          >
            <Typography variant="bodyBold">Choose an amount</Typography>
            <div
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: spacing.xxs,
              }}
            >
              <Button
                text="€10"
                variant="secondary"
                onClick={() => setAmount(10)}
              />
              <Button
                text="€50"
                variant="secondary"
                onClick={() => setAmount(50)}
              />
              <Button
                text="€100"
                variant="secondary"
                onClick={() => setAmount(100)}
              />
              <Button
                text="€200"
                variant="secondary"
                onClick={() => setAmount(200)}
              />
              <Button
                text="€300"
                variant="secondary"
                onClick={() => setAmount(300)}
              />
              <Button
                text="€500"
                variant="secondary"
                onClick={() => setAmount(500)}
              />
              <Button
                text="€1000"
                variant="secondary"
                onClick={() => setAmount(1000)}
              />
              <Button
                text="€2000"
                variant="secondary"
                onClick={() => setAmount(2000)}
              />
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                ':before': {
                  position: 'absolute',
                  top: '50%',
                  width: '3%',
                  height: '1px',
                  content: '"\\a0"',
                  backgroundColor: palette.black,
                  marginLeft: '-10%',
                },
                ':after': {
                  position: 'absolute',
                  top: '50%',
                  width: '3%',
                  height: '1px',
                  content: '"\\a0"',
                  backgroundColor: palette.black,
                  marginLeft: '10%',
                },
              }}
            >
              <Typography>or</Typography>
            </div>
            <Typography variant="bodyBold">Enter an amount</Typography>
            <Input
              label="Amount"
              value={amount.toString()}
              onChange={(e) => setAmount(Number(e.target.value))}
              type="number"
              endAdornment="€"
            />
            <div
              css={{
                display: 'flex',
                gap: spacing.xxxs,
              }}
            >
              <Button
                variant="primary"
                text={userDemo ? 'Pay with Card' : 'Pay (Merchant Side Card)'}
                loading={orderCreateLoading}
                onClick={() => setShowModal(true)}
                disabled={amount === 0}
              />
              <Button
                variant="primary"
                text={
                  userDemo ? 'Pay with Quickbit' : 'Pay (Quickbit Side Card)'
                }
                loading={orderCreateLoading}
                onClick={async () => {
                  await createNewOrder();
                  setShowModal(true);
                }}
                disabled={amount === 0}
              />
            </div>
            {showModal && (
              <Modal closeModal={closeModal}>
                <div
                  css={{
                    height: sizing.large,
                    position: 'relative',
                    borderRadius: spacing.xxxs,
                    overflow: 'hidden',
                    margin: `-${spacing.xxs}`,
                    [breakpoints.laptopSmall]: {
                      width: sizing.large,
                    },
                  }}
                >
                  {!order && (
                    <div
                      css={{
                        width: '100%',
                        height: '100%',
                        padding: spacing.xs,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        css={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: spacing.xxxs,
                        }}
                      >
                        <Typography variant="bodyBold">
                          Enter card details
                        </Typography>
                        <Input
                          label="Card holder name"
                          value={fields.cardHolder}
                          onChange={(e) =>
                            setters.cardHolder.set(e.target.value)
                          }
                        />
                        <Input
                          label="Credit card number"
                          value={fields.creditCardNumber}
                          onChange={(e) =>
                            setters.creditCardNumber.set(e.target.value)
                          }
                        />
                        <div
                          css={{
                            display: 'flex',
                            gap: spacing.xxxs,
                            flexDirection: 'column',
                            [breakpoints.laptopSmall]: {
                              flexDirection: 'row',
                            },
                          }}
                        >
                          <Input
                            label="Expiry month"
                            value={fields.expiryMonth}
                            onChange={(e) =>
                              setters.expiryMonth.set(e.target.value)
                            }
                          />
                          <Input
                            label="Expiry year"
                            value={fields.expiryYear}
                            onChange={(e) =>
                              setters.expiryYear.set(e.target.value)
                            }
                          />
                          <Input
                            label="CVV"
                            value={fields.cvv}
                            onChange={(e) => setters.cvv.set(e.target.value)}
                          />
                        </div>
                        {error && (
                          <div
                            css={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: spacing.xxxs,
                            }}
                          >
                            <div
                              css={{
                                display: 'flex',
                                gap: spacing.xxxs,
                                alignItems: 'center',
                              }}
                            >
                              <Icon name="error" />
                              <Typography
                                variant="bodyBold"
                                color={palette.danger.x500}
                              >
                                Error
                              </Typography>
                            </div>
                            {error.errorCode == 'FORM_VALIDATION_FAILED' ? (
                              error.formErrors.map((formError) => (
                                <Typography
                                  variant="bodySmallBold"
                                  key={formError.reason}
                                  color={palette.danger.x500}
                                >
                                  {formError.reason}
                                </Typography>
                              ))
                            ) : (
                              <Typography
                                variant="bodySmallBold"
                                color={palette.danger.x500}
                              >
                                {error.reason}
                              </Typography>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: spacing.xxxs,
                        }}
                      >
                        <Button
                          text="Cancel"
                          variant="secondary"
                          onClick={closeModal}
                        />
                        <Button
                          text={`Pay €${amount}`}
                          variant="primary"
                          onClick={createNewOrder}
                          disabled={hasErrors}
                          loading={orderCreateLoading}
                        />
                      </div>
                    </div>
                  )}
                  {order && (
                    <div
                      css={{
                        width: '100%',
                        height: '100%',
                        padding: spacing.xxxs,
                      }}
                    >
                      <iframe
                        src={order.checkoutUrl}
                        css={{
                          width: '100%',
                          height: '100%',
                          border: 'none',
                          borderRadius: shape.borderRadius,
                        }}
                        allow="clipboard-write"
                      />
                    </div>
                  )}
                </div>
              </Modal>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};
