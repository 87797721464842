/** @jsxImportSource @emotion/react */
import { DesignSystemProvider } from '@quickbit/qb-design-system';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { MockMerchantOrPlatform, TopUpDemo, PayDemo } from './pages';

function App() {
  return (
    <DesignSystemProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MockMerchantOrPlatform />} />
          <Route path="/top-up" element={<TopUpDemo />} />
          <Route path="/pay" element={<PayDemo />} />
        </Routes>
      </BrowserRouter>
    </DesignSystemProvider>
  );
}

export default App;
