import forge from 'node-forge';
import { CardData, getPublicKey } from 'pages/api';

// Function to encrypt data using RSA with PKCS#1 padding
export const encryptCardData = async (apiKey: string, data: CardData) => {
  const keyResponse = await getPublicKey(apiKey);
  // Convert PEM-formatted public key to a forge-compatible format
  const publicKey = forge.pki.publicKeyFromPem(keyResponse.publicKey);
  // Encrypt data using the public key and PKCS#1 padding
  const encryptedCreditCardNumber = publicKey.encrypt(
    data.creditCardNumber,
    'RSAES-PKCS1-V1_5'
  );
  const encryptedCvv = publicKey.encrypt(data.cvv, 'RSAES-PKCS1-V1_5');
  // Encode the encrypted data to a base64 string for easy handling
  return [
    forge.util.encode64(encryptedCreditCardNumber),
    forge.util.encode64(encryptedCvv),
  ];
};
