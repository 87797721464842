/** @jsxImportSource @emotion/react */
import {
  UtilityButton,
  Typography,
  createStyles,
} from '@quickbit/qb-design-system';
import React from 'react';
import { mapCurrencyCodeToSymbol } from 'utils/mapCurrencyCodeToSymbol';
import { type Product } from 'utils/mockProducts';

interface Props {
  product: Product;
  onAddToCart: () => void;
  onRemoveFromCart: () => void;
  selected?: boolean;
}

export const ProductCard: React.FC<Props> = ({
  product,
  onAddToCart,
  onRemoveFromCart,
  selected = false,
}) => {
  const styles = useStyles();

  const imageUrl =
    process.env.PUBLIC_URL + '/assets/products/' + product.imageName;

  return (
    <div css={styles.card} onClick={!selected ? onAddToCart : onRemoveFromCart}>
      <div css={styles.cardImage}>
        {product.discount && (
          <div css={styles.discount}>
            <Typography variant="bodySmallest">-{product.discount}%</Typography>
          </div>
        )}
        <img src={imageUrl} alt={product.title} />
      </div>
      <div css={styles.cardContent}>
        <div>
          <Typography variant="bodyMedium">{product.title}</Typography>
          <Typography variant="bodyMedium">
            {mapCurrencyCodeToSymbol(product.currencyCode)} {product.price}
          </Typography>
        </div>
        <UtilityButton
          css={selected && styles.selectedAddToCartBtn}
          variant="icon"
          icon={selected ? 'checkmark' : 'plus'}
        />
      </div>
    </div>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  card: {
    maxWidth: '320px',
    padding: spacing.xxs,
    backgroundColor: palette.white,
    borderRadius: spacing.xxxs,
    boxShadow: `0px 1px 1px ${palette.grey.x100}`,
    transition: 'transform 0.2s ease-in-out',
    cursor: 'pointer',
  },
  cardImage: {
    width: '100%',
    height: '300px',
    position: 'relative',
    borderRadius: spacing.xxxs,
    overflow: 'hidden',
    marginBottom: spacing.xs,
    backgroundColor: palette.grey.x300,
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  discount: {
    backgroundColor: palette.danger.x500,
    padding: `${spacing.xxxs} ${spacing.xxs}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    position: 'absolute',
    top: spacing.xxs,
    right: spacing.xxs,
    borderRadius: '50%',
    fontSize: '0.75rem',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectedAddToCartBtn: {
    '&.MuiButton-outlined': {
      background: palette.primary.x400 + '!important',
    },
    svg: {
      color: palette.white,
    },
  },
}));
