/** @jsxImportSource @emotion/react */
import {
  Button,
  ButtonProps,
  createStyles,
  useTheme,
} from '@quickbit/qb-design-system';
import { ReactNode, FunctionComponent } from 'react';
import { Footer, Header } from 'components';

interface Props {
  title?: string;
  headerHidden?: boolean;
  children: ReactNode;
  primaryButtonProps?: Omit<ButtonProps, 'variant'>;
  secondaryButtonProps?: Omit<ButtonProps, 'variant'>;
}

export const Layout: FunctionComponent<Props> = ({
  children,
  title,
  primaryButtonProps,
  secondaryButtonProps,
  headerHidden,
}) => {
  const styles = useStyles();
  const { spacing } = useTheme();

  return (
    <>
      {!headerHidden && <Header title={title} />}
      <div
        css={[
          { ...styles.container },
          {
            height: `${headerHidden ? '100vh' : `calc(100vh - ${spacing.m})`}`,
          },
        ]}
      >
        <main css={styles.content}>{children}</main>
        <section css={styles.footer}>
          {(primaryButtonProps || secondaryButtonProps) && (
            <div css={styles.buttonContainer}>
              {primaryButtonProps && (
                <Button
                  variant="primary"
                  text={primaryButtonProps.text}
                  icon={primaryButtonProps.icon}
                  loading={primaryButtonProps.loading}
                  disabled={primaryButtonProps.disabled}
                  onClick={primaryButtonProps.onClick}
                />
              )}
              {secondaryButtonProps && (
                <>
                  <div css={styles.buttonSeparator} />
                  <Button
                    variant="secondary"
                    text={secondaryButtonProps.text}
                    icon={secondaryButtonProps.icon}
                    loading={secondaryButtonProps.loading}
                    disabled={secondaryButtonProps.disabled}
                    onClick={secondaryButtonProps.onClick}
                  />
                </>
              )}
            </div>
          )}
          <Footer />
        </section>
      </div>
    </>
  );
};

const useStyles = createStyles(({ spacing, breakpoints, sizing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${spacing.s} ${spacing.xxs} ${spacing.m} ${spacing.xxs}`,
    [breakpoints.laptopSmall]: {
      alignItems: 'center',
      paddingBottom: '0px',
    },
  },
  content: {
    flex: '1 1 auto',
    overflow: 'hidden',
    overflowY: 'auto',
    [breakpoints.laptopSmall]: {
      maxWidth: `calc(2 * ${sizing.large})`,
      width: '100%',
      paddingBottom: `calc(2 * ${spacing.m})`,
    },
  },
  buttonSeparator: {
    marginTop: spacing.xxs,
  },
  buttonContainer: {
    margin: `${spacing.xxs} 0`,
    [breakpoints.laptopSmall]: {
      marginTop: '0',
    },
  },
  footer: {
    width: '100%',
    maxWidth: sizing.medium,
    margin: 'auto',
    [breakpoints.laptopSmall]: {
      flex: 1,
      paddingLeft: spacing.m,
      paddingRight: spacing.m,
    },
  },
}));
