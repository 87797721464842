import { camelCase, snakeCase, isArray, transform, isObject } from 'lodash';

const BASE_URL = process.env.REACT_APP_BASE_URL;
if (!BASE_URL) {
  throw Error('Missing BASE_URL env variable');
}

const changeCasing = (
  obj: Record<string, unknown>,
  modifier: (string: string) => string
) =>
  transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = isArray(target) ? key : modifier(key);
      result[camelKey] = isObject(value)
        ? changeCasing(value as Record<string, unknown>, modifier)
        : value;
    }
  );

type ErrorCode = 'INTERNAL_SERVER_ERROR';

interface GeneralErrorResponse {
  errorCode: ErrorCode;
  reason: string;
}

interface OrderNotFoundResponse {
  errorCode: 'GET_ORDER_NOT_FOUND';
  reason: string;
}

interface FormError {
  parameter: string;
  reason: string;
  errorCode: string;
}
interface FormErrorResponse {
  errorCode: 'FORM_VALIDATION_FAILED';
  reason: 'FORM_VALIDATION_FAILED';
  formErrors: FormError[];
}

export type ErrorResponse =
  | GeneralErrorResponse
  | FormErrorResponse
  | OrderNotFoundResponse;

type RequestOptions = Omit<RequestInit, 'body'> & {
  body?: Record<string, unknown>;
};

/**
 * A wrapper function for making API calls
 * Can be use to modify the fetch requests, modify headers etc etc globally
 * We can add a second optional argument options of type object to achieve the same
 *
 * In the case of an error the BE will response it will either:
 * - ErrorResponse as above. Then it's a "known" error in the BE.
 * - Not Json, empty json or a json with {details}.
 *
 * For the latter case it's a more internal error and we don't want to expose
 * that to the end user.
 *
 * We always have snake_case in the BE and camelCase in the FE. Hence we need to
 * change the casing.
 */
export const fetchAPI = async <T>(
  url: string,
  options?: RequestOptions
): Promise<T> => {
  let data = null;
  try {
    const response = await fetch(`${BASE_URL}/api/${url}`, {
      ...options,
      body: options?.body
        ? JSON.stringify(changeCasing(options.body, snakeCase))
        : undefined,
      headers: {
        ...options?.headers,
        'Content-Type': 'application/json',
        'Accept-Language': 'en-US',
      },
      credentials: 'include',
    });
    data = changeCasing(await response.json(), camelCase);
    if (response.ok) {
      return data as T;
    }
    if (!('errorCode' in data)) throw Error('Generic error');
  } catch (exception) {
    console.error(exception);
    throw {
      errorCode: 'INTERNAL_SERVER_ERROR',
      reason: 'A server error occurred.',
    } as ErrorResponse;
  }
  if (!('reason' in data)) {
    data['reason'] = data.errorCode;
  }
  throw data as unknown as ErrorResponse;
};
