export type Product = {
  id: string;
  title: string;
  price: number;
  currencyCode: 'EUR';
  category: string;
  imageName: string;
  discount?: number;
};

export const products: Product[] = [
  {
    id: 'a88c954b-3acd-46f8-be82-0d7cb337f886',
    title: 'Air Jordan 1 Retro High OG',
    price: 3.2,
    currencyCode: 'EUR',
    category: 'shoes',
    imageName: 'Air_Jordan_1_Retro_High_OG.png',
    discount: 97,
  },
  {
    id: 'c98846e0-a800-427e-90b4-8170b4a37ecf',
    title: 'US4-S Gel-Terrain',
    price: 1,
    currencyCode: 'EUR',
    category: 'shoes',
    imageName: 'US4-S_Gel-Terrain.png',
    discount: 99,
  },
  {
    id: 'd694bd7f-2f1a-475e-bba0-34534096206a',
    title: 'Samba OG',
    price: 2.5,
    currencyCode: 'EUR',
    category: 'shoes',
    imageName: 'Samba_OG.png',
    discount: 98,
  },
  {
    id: 'e04fb83d-32cb-4f14-a650-af1c03b3c4a9',
    title: 'Crocs Classic',
    price: 4,
    currencyCode: 'EUR',
    category: 'shoes',
    imageName: 'Crocs_Classic.png',
    discount: 97,
  },
];
