/** @jsxImportSource @emotion/react */
import {
  Button,
  Icon,
  Typography,
  createStyles,
} from '@quickbit/qb-design-system';
import React from 'react';
import { mapCurrencyCodeToSymbol } from 'utils/mapCurrencyCodeToSymbol';
import { type Product } from 'utils/mockProducts';

interface Props {
  items: Product[];
  loading: boolean;
  disabled: boolean;
  onCheckout: () => void;
  onClearCart: () => void;
}

export const Cart: React.FC<Props> = ({
  items,
  loading,
  disabled,
  onCheckout,
  onClearCart,
}) => {
  const styles = useStyles();

  return (
    <div css={styles.cart}>
      <div css={styles.cartContent}>
        <div css={styles.cartHeading}>
          <Typography variant="heading2">Order Summary</Typography>
          <Typography variant="body">
            {items.length} {items.length > 1 ? 'Items' : 'Item'}
          </Typography>
          <div onClick={onClearCart} css={styles.clearCart}>
            <Icon name="trash" size="small" />
          </div>
        </div>

        {items.map((item) => (
          <div key={item.id} css={styles.cartItem}>
            <Typography variant="bodyMedium">{item.title}</Typography>
            <Typography variant="body">
              {mapCurrencyCodeToSymbol(item.currencyCode)} {item.price}
            </Typography>
          </div>
        ))}

        <div css={styles.cartTotal}>
          <Typography variant="bodyBold">Total</Typography>
          <Typography variant="bodyBold">
            {mapCurrencyCodeToSymbol(items[0].currencyCode)}{' '}
            {items.reduce((acc, item) => acc + item.price, 0)}
          </Typography>
        </div>
        <Button
          css={styles.checkoutBnt}
          variant="primary"
          text="Pay Now"
          loading={loading}
          onClick={onCheckout}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

const useStyles = createStyles(({ spacing, palette, sizing }) => ({
  cart: {
    width: '100%',
    maxWidth: sizing.large,
    padding: spacing.xxs,
    backgroundColor: palette.white,
    borderRadius: spacing.xxxs,
    boxShadow: `0px 1px 1px ${palette.grey.x200}`,
    transition: 'transform 0.2s ease-in-out',
  },
  cartContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cartHeading: {
    marginBottom: spacing.xs,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cartItem: {
    marginTop: spacing.xxxs,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':not(:last-child)': {
      borderBottom: `1px solid ${palette.grey.x200}`,
    },
  },
  cartTotal: {
    marginTop: spacing.m,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  checkoutBnt: {
    marginTop: spacing.l,
  },
  clearCart: {
    cursor: 'pointer',
    svg: {
      width: spacing.xs,
      height: spacing.xs,
    },
  },
}));
