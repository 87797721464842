/** @jsxImportSource @emotion/react */
import {
  Icon,
  Modal,
  Snackbar,
  createStyles,
} from '@quickbit/qb-design-system';
import { useEffect, useState } from 'react';
import {
  useAsyncFn,
  useDebounce,
  useLocalStorage,
  useMount,
  useSessionStorage,
} from 'react-use';
import { Order } from 'types';
import { type Product, products } from 'utils/mockProducts';
import { createOrder, getOrder } from '../api';
import { Cart } from './components/Cart';
import { Navbar } from './components/Navbar';
import { ProductList } from './components/ProductList';

export function PayDemo() {
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [merchantApiKey] = useLocalStorage<string | null>('apiKey', null);
  const [cart, setCart] = useSessionStorage<Product[]>('pay-demo-cart', []);
  const [order, setOrder] = useSessionStorage<Order | undefined>(
    'pay-demo-order',
    undefined
  );

  const [{ loading: orderCreateLoading }, createNewOrder] =
    useAsyncFn(async () => {
      if (!merchantApiKey) return undefined;
      if (cart.length === 0) return undefined;
      const amount = cart.reduce((acc, curr) => acc + curr.price, 0);
      const res = await createOrder({
        amount,
        currency: cart[0].currencyCode,
        apiKey: merchantApiKey,
      });
      setShowModal(true);
      setOrder(res);
      return res;
    }, [cart, merchantApiKey]);

  const [{ loading: getOrderStatusLoading }, getOrderStatus] =
    useAsyncFn(async () => {
      if (!merchantApiKey || !order) return undefined;
      const res = await getOrder(order.uuid, merchantApiKey);
      setOrder({ ...order, status: res.status });
    }, [order, merchantApiKey, showModal]);

  useEffect(() => {
    if (order?.status === 'COMPLETED') {
      setTimeout(() => {
        setCart([]);
        setOrder(undefined);
      }, 10000);
    }
  }, [order, setCart, setOrder]);

  useMount(() => {
    if (order) setShowModal(true);
    return () => {
      setShowModal(false);
    };
  });

  useDebounce(
    () => {
      if (order && !getOrderStatusLoading) getOrderStatus();
    },
    60000, // 60 seconds
    [order, getOrderStatusLoading]
  );

  const handleCancelOrder = () => {
    setCart([]);
    setOrder(undefined);
    setShowModal(false);
  };

  return (
    <div css={styles.container}>
      <Navbar />

      <div css={styles.content}>
        <ProductList
          products={products}
          selectedProducts={cart}
          disabled={orderCreateLoading || order?.status === 'PENDING'}
          onAddToCart={(item: Product) => setCart([...cart, item])}
          onRemoveFromCart={(item) =>
            setCart([...cart.filter((p) => p.id !== item.id)])
          }
        />

        {cart.length > 0 && (
          <Cart
            items={cart}
            loading={orderCreateLoading || order?.status === 'PENDING'}
            onCheckout={() => createNewOrder()}
            disabled={!merchantApiKey || order?.status === 'PENDING'}
            onClearCart={() => setCart([])}
          />
        )}

        {order && showModal && (
          <Modal
            closeModal={() => {
              order?.status !== 'PENDING' && setShowModal(false);
            }}
          >
            <div css={styles.modalHeader} onClick={handleCancelOrder}>
              <Icon name="close" />
            </div>
            <div css={styles.modalContent}>
              <iframe
                src={order.checkoutUrl}
                css={{ width: '100%', height: '100%', border: 'none' }}
                allow="clipboard-write"
              />
            </div>
          </Modal>
        )}
      </div>
      <Snackbar
        variant="danger"
        message="You need to set your API key to use this demo."
        open={!merchantApiKey}
      />
    </div>
  );
}

const useStyles = createStyles(({ spacing, breakpoints, sizing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100dvh',
    padding: `${spacing.xxs} ${spacing.s}`,
    [breakpoints.laptopMedium]: {
      maxWidth: 1400,
      width: '100%',
      margin: '0 auto',
    },
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.m,
    marginTop: spacing.s,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: spacing.xxs,
  },
  modalContent: {
    height: sizing.large,
    position: 'relative',
    borderRadius: spacing.xxxs,
    overflow: 'hidden',
    [breakpoints.laptopSmall]: {
      width: sizing.large,
    },
  },
}));
