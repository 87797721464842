/** @jsxImportSource @emotion/react */
import { createStyles, Typography } from '@quickbit/qb-design-system';
import React from 'react';

export const Navbar: React.FC = () => {
  const styles = useStyles();

  return (
    <header css={styles.header}>
      <Typography color="primary" variant="bodyBold">
        NEUE STORE
      </Typography>
      <nav css={styles.navItems}>
        <ul>
          <li>Home</li>
          <li>Shoes</li>
          <li>Clothes</li>
          <li>Accessories</li>
        </ul>
      </nav>
    </header>
  );
};

const useStyles = createStyles(
  ({ spacing, palette, typography, breakpoints }) => ({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: spacing.m,
      padding: `${spacing.xxxs} ${spacing.s}`,
      borderBottom: `1px solid ${palette.grey.x100}`,
      borderRadius: spacing.xxxs,
      backgroundColor: palette.white,
      width: '100%',
    },
    navItems: {
      display: 'none',
      [breakpoints.tabletSmall]: {
        display: 'block',
        ul: {
          display: 'flex',
          gap: spacing.s,
          listStyle: 'none',
          padding: 0,
          li: {
            color: palette.grey.x400,
            ...typography.bodySmallMedium,
            // make second li selected
            '&:nth-of-type(2)': {
              cursor: 'pointer',
              color: palette.grey.x900,
            },
          },
        },
      },
    },
  })
);
