/** @jsxImportSource @emotion/react */
import { createStyles } from '@quickbit/qb-design-system';
import React from 'react';
import { type Product } from 'utils/mockProducts';
import { ProductCard } from './ProductCard';

interface Props {
  products: Product[];
  onAddToCart: (product: Product) => void;
  onRemoveFromCart: (product: Product) => void;
  selectedProducts: Product[];
  disabled?: boolean;
}

export const ProductList: React.FC<Props> = ({
  products,
  selectedProducts,
  onAddToCart,
  onRemoveFromCart,
  disabled = false,
}) => {
  const styles = useStyles();

  return (
    <ul css={styles.list}>
      {products.map((item) => (
        <li key={item.id}>
          <ProductCard
            product={item}
            onAddToCart={() => !disabled && onAddToCart(item)}
            onRemoveFromCart={() => !disabled && onRemoveFromCart(item)}
            selected={selectedProducts.some((p) => p.id === item.id)}
          />
        </li>
      ))}
    </ul>
  );
};

const useStyles = createStyles(({ spacing }) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: spacing.xxs,
    width: '100%',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
}));
