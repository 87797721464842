/** @jsxImportSource @emotion/react */
import { createStyles, Typography } from '@quickbit/qb-design-system';
import { FunctionComponent } from 'react';

interface Props {
  title?: string;
}

export const Header: FunctionComponent<Props> = ({ title }) => {
  const styles = useStyles();

  return (
    <header css={styles.header}>
      {title && (
        <div css={styles.title}>
          <Typography variant="bodyBold">{title}</Typography>
        </div>
      )}
    </header>
  );
};

const useStyles = createStyles(({ spacing, palette }) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    minHeight: spacing.m,
    padding: `${spacing.xxxs} ${spacing.s}`,
    borderBottom: `1px solid ${palette.grey.x100}`,
    backgroundColor: palette.white,
    width: '100%',
  },
  title: {
    margin: '0 auto',
  },
}));
